import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { breakpointService } from '../../domain/services/breakpointService';
import { OurPicks } from './OurPicks';
import { YouMightAlsoLike } from './YouMightAlsoLike';
import { sharedMemory } from '../../domain/services/sharedMemoryService';
import { eventService } from '../../domain/services/eventService';

export const Recommendation = ({ content, isTransported, isExpanded }) => {
  const [componentName, setComponentName] = useState();

  const listener = () => {
    if (sharedMemory.get('transporter-paths')?.size > 0) {
      const renderComponent =
        breakpointService.isMobile() && isTransported && !isExpanded
          ? 'OurPicks'
          : 'YouMightAlsoLike';
      if (componentName !== renderComponent) {
        setComponentName(renderComponent);
      }
    }
  };

  useEffect(() => {
    eventService.addEventListener('transporterScrollingSetDefined', listener, {
      once: true
    });

    return () => {
      eventService.removeEventListener(
        'transporterScrollingSetDefined',
        listener
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [componentName, isTransported, isExpanded]);

  const readMoreListener = event => {
    if (
      event.detail?.content?.id === content.id &&
      componentName !== 'YouMightAlsoLike'
    ) {
      setComponentName('YouMightAlsoLike');
    }
    return null;
  };

  useEffect(() => {
    document.addEventListener('readMoreButtonClicked', readMoreListener);
    return () => {
      document.removeEventListener('readMoreButtonClicked', readMoreListener);
    };
  }, [componentName, isTransported, isExpanded]);

  if (componentName) {
    const Component =
      componentName === 'OurPicks' ? OurPicks : YouMightAlsoLike;
    return <Component content={content} />;
  }
  return <div />;
};

Recommendation.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  isTransported: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired
};
