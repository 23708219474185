import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { conf } from '../../domain/services/configService';
import { apiClientService } from '../../domain/services/apiClientService';
import { sharedMemory } from '../../domain/services/sharedMemoryService';
import { RecommendationBox } from './RecommendationBox';
import { domainService } from '../../domain/services/domainService';

export const OurPicks = ({ content }) => {
  const [paths, setPaths] = useState([]);

  const getPaths = async () => {
    // Add current content's path to transporterPaths to avoid repeating it.
    const transporterPaths = sharedMemory.get('transporter-paths');
    transporterPaths.add(content.url.path);

    const domain = conf.isStage
      ? conf.s3BucketPublicDomain
      : conf.canonicalDomain;
    const domainPart = domainService.isRunningOnCMS() ? `//${domain}` : '';
    const { response } = await apiClientService.getJson(
      `${domainPart}/api/content/latest-contents-by-vertical-category.json`
    );

    if (response) {
      const results = Object.values(response)
        .map(pttItems =>
          pttItems.find(item => {
            const isRepeated = transporterPaths.has(item.url);
            if (!isRepeated) {
              // Add this path ASAP, to avoid repeated contents
              // from latest-contents-by-vertical-category.json
              transporterPaths.add(item.url);
              return true;
            }
            return false;
          })
        )
        .sort((a, b) => b.modificationDate - a.modificationDate)
        .map(item => item.url);

      if (results.length > 0) {
        setPaths(results);
      }
    }
  };

  useEffect(() => {
    getPaths();
  }, []);

  return <RecommendationBox paths={paths} type="our-picks" />;
};

OurPicks.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
