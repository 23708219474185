import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { t } from '../../domain/services/configService';
import { theme } from '../../domain/theme';
import { facePaint } from '../../domain/theme/utils/facePaint';
import { resourceService } from '../../domain/services/resourceService';
import { CardItemVerticalLegacy } from '../responsive/organisms/CardItemVerticalLegacy';
import {
  mapContentToCardData,
  removeAttributesFromCardData,
  removeAuthorsAndDateFromCardData,
  removeTaxonomyElementsFromCardData
} from '../../domain/utils/contentUtils';
import { ContainerMain } from '../responsive/atoms/ContainerMain';
import { HorizontalScrollContainer } from '../responsive/organisms/HorizontalScrollContainer';
import { Loading } from '../responsive/atoms/Loading';

const styles = {
  container: css`
    background-color: #222;
    ${facePaint({
      padding: [
        theme.spacing.parse('$base 0 $xs 0'),
        theme.spacing.parse('$l 0 $xs 0'),
        theme.spacing.parse('$xl 0 $xs 0'),
        theme.spacing.parse('$xl2 0 $xs 0')
      ]
    })};
    min-height: 400px;
    & button {
      ${facePaint({
        display: ['none', 'none', 'block', 'block']
      })};
    }
  `,
  header: css`
    ${theme.font.family('boldWeb')};
    text-align: center;
    position: relative;
    padding-bottom: ${theme.spacing('s')};
    margin-bottom: ${theme.spacing('xl')};
    ${facePaint({
      'font-size': [
        theme.font.size('l'),
        theme.font.size('xl'),
        theme.font.size('xl2'),
        theme.font.size('xl2')
      ]
    })};
    letter-spacing: ${theme.letterSpacing('base')};
    color: #fff;
    text-transform: uppercase;
    &:after {
      content: '';
      position: absolute;
      width: 90px;
      height: 4px;
      background: #fc0;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,
  cardContainer: css`
    display: inline-flex;
    white-space: nowrap;
    overflow-x: visible;
    overflow-y: visible;
    text-align: left;
  `,
  cardItem: css`
    display: inline-block;
    ${facePaint({
      'min-width': ['200px', '200px', '240px', '240px'],
      width: ['200px', '200px', '240px', '240px']
    })};
    margin-right: ${theme.spacing('s')};
    white-space: initial;
    &:last-child {
      ${facePaint({
        'margin-right': theme.spacing(['base', 'l', 'xl2'])
      })};
    }
  `,
  loader: css`
    padding-top: ${theme.spacing('xl3')};
  `
};

export const RecommendationBox = ({ paths, type }) => {
  const [contents, setContents] = useState([]);

  const fetchContents = async () => {
    if (paths.length > 0) {
      const promises = paths.map(path =>
        resourceService.getJsonForPathname(path)
      );
      const validResults = [];
      const results = await Promise.allSettled(promises);
      results.forEach(result => {
        if (result.status !== 'rejected' && result.value) {
          validResults.push(result.value);
        }
      });
      setContents(validResults);
    }
  };

  useEffect(() => {
    fetchContents();
  }, [paths]);

  return (
    <div css={[styles.container]}>
      <h3 css={styles.header}>
        {t(type === 'ymal' ? 'You might also like' : 'Our picks')}
      </h3>
      {contents.length > 0 ? (
        <HorizontalScrollContainer
          backgroundColor="deepGray"
          arrow={{ color: 'white', top: '15%', size: 32 }}
          gradient={{ prev: ['10%', '100%'], next: ['0%', '90%'] }}
        >
          <ContainerMain>
            <div css={styles.cardContainer}>
              {contents.map(content => {
                const cardData = removeTaxonomyElementsFromCardData(
                  removeAuthorsAndDateFromCardData(
                    removeAttributesFromCardData(
                      mapContentToCardData(content),
                      ['bgImageEntity']
                    )
                  )
                );

                // Set white background color for these cards.
                if (cardData.sponsored) {
                  cardData.sponsored.bgColor = 'white';
                }

                return (
                  <div
                    css={styles.cardItem}
                    className={type === 'ymal' ? 'gtm__ymal' : 'gtm__ourpicks'}
                    key={content.url.path}
                  >
                    <CardItemVerticalLegacy
                      data={cardData}
                      aspect="4x3"
                      imageMaxWidths={{
                        mobile: 200,
                        tablet: 200,
                        small_desktop: 240,
                        large_desktop: 240
                      }}
                      size="recommendation-box"
                      displayShowAsArticle
                      keepTaxonomies
                    />
                  </div>
                );
              })}
            </div>
          </ContainerMain>
        </HorizontalScrollContainer>
      ) : (
        <div css={styles.loader}>
          <Loading />
        </div>
      )}
    </div>
  );
};

RecommendationBox.propTypes = {
  paths: PropTypes.arrayOf(PropTypes.string).isRequired,
  type: PropTypes.oneOf(['ymal', 'our-picks']).isRequired
};
