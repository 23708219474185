import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { apiClientService } from '../../domain/services/apiClientService';
import { conf } from '../../domain/services/configService';
import { sharedMemory } from '../../domain/services/sharedMemoryService';
import { RecommendationBox } from './RecommendationBox';

const limit = 5;

export const YouMightAlsoLike = ({ content }) => {
  const [paths, setPaths] = useState([]);

  const getPaths = async () => {
    // Add current content's path to transporterPaths to avoid repeating it.
    const transporterPaths =
      sharedMemory.getOrInitializeSetObject('transporter-paths');
    transporterPaths.add(content.url.path);

    const results = [];

    // Check if we have manual contents
    if (content.ymalIsManual) {
      content.ymalManualContents.forEach(item => {
        if (
          item.entity?.manualContent?.entity?.entityUrl?.path &&
          !transporterPaths.has(
            item.entity.manualContent.entity.entityUrl.path
          ) &&
          (!item.entity.expireDate ||
            item.entity.expireDate?.timestamp * 1000 > Date.now())
        ) {
          const { path } = item.entity.manualContent.entity.entityUrl;
          results.push(path);
          transporterPaths.add(path);
        }
      });
    }

    // If manual contents do not reach the limit of contents to show, get more dynamically.
    if (results.length < limit) {
      const { response } = await apiClientService.getJson(
        `${conf.searchEndpoint}?handler=recommendation&nid=${content.id}&limit=40&avoidBranded=1`
      );

      if (response?.results) {
        response.results.forEach(path => {
          if (results.length < limit && !transporterPaths.has(path)) {
            // Add this path ASAP, to avoid repeated contents
            transporterPaths.add(path);
            results.push(path);
          }
        });
      }
    }

    if (results.length > 0) {
      setPaths(results);
    }
  };

  useEffect(() => {
    getPaths();
  }, []);

  return <RecommendationBox paths={paths} type="ymal" />;
};

YouMightAlsoLike.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired
};
